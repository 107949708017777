import axios from "axios";
import store from "store";
import { signOutSuccess } from "store/slices/authSlice";
import { connect } from "react-redux";
import { APP_PREFIX_SETTING_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import NotificationMessage from "./NotificationMessage";
import { message } from "antd";
import { t } from "i18next";
import {
    sendRequest, receiveResponse
} from 'store/slices/authSlice';

const EKAxios = axios.create();
EKAxios.interceptors.request.use(config => {
    const locale = store.getState().theme.locale;
    const token = store.getState().auth.token
    if (config.method === 'post' || config.method === 'put' || config.method === 'patch') {
        store.dispatch(sendRequest());
    }
    const headers = {
        'Authorization': `token ${token}`,
        'Accept-Language': locale,
    }
    config.headers['Accept-Language'] = locale;
    config.params = config.params || {};
    config.data = config.data || undefined;
    config.headers = headers || {};
    return config;
});


EKAxios.interceptors.response.use(
    response => {
        store.dispatch(receiveResponse())
        if (response.data.class_name !== undefined) {

            if (response.data.class_name === "lava.utils.Result") {
                const result = response.data.result;
                const message = response.data.message;
                if (result === 'success') {
                    NotificationMessage.openNotificationWithIcon('success', t('Succès'), message);
                } else if (result === "warning") {
                    NotificationMessage.openNotificationWithIcon('warning', t('Avertissement'), message);
                } else if (result === "error") {
                    NotificationMessage.openNotificationWithIcon('error', t('Erreur'), message);
                }
            }
        } else {
            return response;
        }
        return response;
    },
    error => {
        store.dispatch(receiveResponse())
        if (!error.response) {
            message.error('There was a problem connecting to the server. Please check your internet connection and try again.');
        }

        if (error.response.status === 401) {
            localStorage.clear()
            window.location = `${AUTH_PREFIX_PATH}/login`
        }

        if (error.response.status === 400) {
            if (error.response.data.class_name !== undefined) {
                if (error.response.data.class_name === "lava.utils.Result") {
                    const message = error.response.data.message;
                    NotificationMessage.openNotificationWithIcon('error', t('Erreur'), message);

                }
            }
            // else if (error.response.statusText === "Bad Request") {
            //     let data = error.response.data;
            //     for (const [key, value] of Object.entries(data)) {
            //         NotificationMessage.openNotificationWithIcon('error', t('Erreur'), value);
            //     }

            // }
            else {
                if (error.response.data.name !== undefined) {
                    let message = error.response.data.name[0];
                    NotificationMessage.openNotificationWithIcon('error', t('Erreur'), message);
                }
                return error.response;
            }
            return error.response;
        }

        if (error.response.status === 200) {
            if (error.response.data.class_name !== undefined) {
                if (error.response.data.class_name === "lava.utils.Result") {
                    const message = error.response.data.message;
                    NotificationMessage.openNotificationWithIcon('success', t('Succès'), message);
                }
            } else {
                if (error.response.data.name !== undefined) {
                    let message = error.response.data.name[0];
                    NotificationMessage.openNotificationWithIcon('error', t('Erreur'), message);

                }
                return error.response;
            }
            return error.response;
        }


        if (error.response.status === 400) {
            if (error.response.data.class_name !== undefined) {
                if (error.response.data.class_name === "lava.utils.Result") {
                    const message = error.response.data.message;
                    NotificationMessage.openNotificationWithIcon('error', t('Erreur'), message);
                }
            } else {
                const message = error.response.data.detail
                NotificationMessage.openNotificationWithIcon('error', t('Erreur'), message);
                store.dispatch(signOutSuccess());
                window.location = "/auth/login/"
                return error.response;
            }
            return error.response;
        }
        if (error.response.status === 403) {
            window.location = `${APP_PREFIX_SETTING_PATH}/access-denied`
            return error.response;
        }
        if (error.response.status === 403) {
            if (error.response.data.class_name !== undefined) {
                if (error.response.data.class_name === "lava.utils.Result") {
                    const message = error.response.data.message;
                    NotificationMessage.openNotificationWithIcon('error', t('Erreur'), message);

                }
            } else {
                // store.dispatch(signOutSuccess());
                // window.location = "/auth/login/"
                const message = error.response.data.detail || t("You don't have permission")
                NotificationMessage.openNotificationWithIcon('error', t('Erreur'), message);
                return error.response;
            }
            return error.response;
        }

        if (error.response.status === 404) {
            window.location = `${APP_PREFIX_SETTING_PATH}/notFoundPage`
            return error.response;
        }


        if (error.code === "ERR_NETWORK") {
            // network error
            return error.response;
        }

        if (error.code === "ERR_INTERNET_DISCONNECTED") {
            // network error
            return error.response;
        }

        if (error.response.status === 500) {
            if (error.response.data.class_name !== undefined) {
                if (error.response.data.class_name === "lava.utils.Result") {
                    const message = error.response.data.message;
                    NotificationMessage.openNotificationWithIcon('error', t('Erreur'), message);
                }
            } else {
                const message = t("errors.error_500_message")
                NotificationMessage.openNotificationWithIcon('error', t('Erreur'), message);
            }

            return error.response;
        }


        return Promise.reject(error);
    },
);

const mapStateToProps = ({ auth }) => {
    const { loading, message, showMessage, token, redirect } = auth;
    return { loading, message, showMessage, token, redirect }
}

export default connect(mapStateToProps)(EKAxios);
